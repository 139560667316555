import Formatting from "./formattingJson";

const Messages = {
  updateBank: {
    success: "Berhasil Update Bank",
    failed: "Gagal Update Bank",
  },
  login: {
    success: "Berhasil login",
    failed: "Email atau password salah",
  },
  funding: {
    success: "Pendanaan berhasil",
    failed: "Pendanaan gagal",
  },
  updatePassword: {
    success: "Berhasil Update Password",
    failed: "Gagal Update Password",
  },
  updateAutolanding: {
    success: "Berhasil Update Pendanaan",
    failed: "Gagal Update Pendanaan",
  },
  postWithDraw: {
    success: "Berhasil Mengajukan Permohonan",
    failed: "Gagal Mengajukan Permohonan",
  },
  getOtp: {
    success: "Berhasil Mengirim OTP",
    failed: "Gagal Mengirim OTP",
  },
  confirmOtp: {
    success: "Berhasil Konfirmasi OTP",
    failed: "Gagal Konfirmasi OTP",
  },
  resetPassword: {
    success: "Berhasil Reset Password, Silahkan Cek Email",
    failed: "Gagal Reset Password",
  },
  copyReferalCode: 'Kode Referal Berhasil di Salin',
  copyVA: 'Nomor VA Berhasil di Salin',
  unauthorized: "Mohon login kembali",
  postVoucer: (nominal = "") => {
    
    return {
      success: `Selamat kode voucher senilai \nRp. ${Formatting.currency(
        nominal
      )} berhasil didapatkan`,
      failed: "Gagal mendapatkan voucer",
    };
  },
};

export default Messages;
