import { toast } from "react-toastify";
import Api from "../../utils/api";
import {
  deleteAllToken,
  doEraseAllReload,
  saveToken,
} from "../../utils/cookie";
import Messages from "../../utils/message";

const AuthApi = {
  CounterHit: function () {
    return Api()
      .Get("user/hit")
      .then(({data}) => {
          return data;
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

export default AuthApi;
