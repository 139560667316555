import React from 'react';
import ReactDOM from 'react-dom';
import "./styles/css/custom.css";
import "./styles/css/index.css";
import { App } from "./App";
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs';

require("dotenv").config();
require("dayjs/locale/id");
dayjs.extend(utc)
dayjs.extend(timezone)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
