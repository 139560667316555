import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../utils/api";
import { deleteAllToken, doEraseAllReload, saveToken } from "../../utils/cookie";
import Messages from "../../utils/message";

const UsersApi = {
  getCEO: function () {
    return Api()
      .Get("user/direksi")
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getPortofolioDanain: function () {
    return Api()
      .Get("us/portofolio")
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getLiputan: function (params) {
    return Api()
      .Get(`user/liputan?${params}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getTestimoniPeminjam: function (params) {
    return Api()
      .Get(`user/borrower/testimoni/${params}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  // getTestimoniPeminjam: function (params) {
  //   return Api()
  //     .Get(`pinjaman/testimoni${params}`)
  //     .then(({ data }) => {
  //       return data;
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // },
  getMockupPendana: function (params) {
    return Api()
      .Get(`user/produk`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getKeuntungan: function (params) {
    return Api()
      .Get(`user/keunutungan/${params}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getProfileCEO: function (params) {
    return Api()
      .Get(`user/ceo?id=${params}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getTestimoniPendana: function (params) {
    return Api()
      .Get(`user/borrower/testimoni/${params}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  postKontakKami: function (data) {
    return Api()
      .postRaw(`us`, { request: data }, { rawJSON: true })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getListCareer: function (params) {
    return Api()
      .Get(`us/karir`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getDetailCareer: function (params) {
    return Api()
      .Get(`us/karir?id=${params}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getFAQ: function () {
    return Api()
      .Get(`us/faq`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getDetailFAQ: function (params) {
    return Api()
      .Get(`us/faq?faqid=${params}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  postApplyCareer: function (data) {
    return Api()
      .postRaw(`tentangkami/karir`, data, { rawJSON: true })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  postCV: function (data) {
    return Api()
      .PostFile(`tentangkami/karir`, data, { multipartForm: true })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return err.response.data;
      });
  },
  getListFAQ: function (parms) {
    return Api()
      .Get(`us/faq?${parms}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getTopFAQ: function (parms) {
    return Api()
      .Get(`tentangkami/faqTop`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getTKB: function (parms) {
    return Api()
      .Get(`us/tkb`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
};

export default UsersApi;
