import React, { useEffect, useState } from "react";
import './FooterPages.css'
import logodanain from '../../styles/images/banner-peminjam/LOGODANAIN(White)2.png';
import logotelp from '../../styles/images/banner-peminjam/Vectortelp.png';
import logoemail from '../../styles/images/banner-peminjam/feather_mail.png';
import logoig from '../../styles/images/instagram.svg';
import logofb from '../../styles/images/banner-peminjam/Vectorfb.png';
import logotwitter from '../../styles/images/banner-peminjam/Vectortwitter.png';
import logoappstore from '../../styles/images/banner-peminjam/apple.png';
import logowa from '../../styles/images/whatsapp.svg';
import logotiktok from '../../styles/images/tiktok.svg';

import logogoogle from '../../styles/images/banner-peminjam/google.png';
import copyright from '../../styles/images/banner-peminjam/vaadin_copyright.png';
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import dayjs from "dayjs";

const FooterPages = ({ children}) => {

  let history = useHistory();
  const [showKeuangan, setShowKeuangan] = useState(false);
  const [show, setShow] = useState(false);

  const Tentangkami = () => {
    history.push("/tentang-kami")
  }
  const Beranda = () => {
    history.push("/beranda")
  }
  const Syaratket = () => {
    history.push("/syaratketentuan")
  }
  const Kebijakanpriv = () => {
    history.push("/kebijakanprivasi")
  }
  const Pengaduanpengguna = () => {
    history.push("/pengaduan-pengguna")
  }
  const Resikopeminjam = () => {
    history.push("/resikopeminjam")
  }
  const Resikopendanaan = () => {
    history.push("/resikopendanaan")
  }
  const Hubkami = () => {
    history.push("/hubungikami")
  }
  const Faq = () => {
    history.push("/bantuan")
  }
  const Career = () => {
    history.push("/career")
  }

  return (
    <>
      <Modal contentClassName="custom-modal-style" scrollable centered isOpen={showKeuangan} toggle={() => setShowKeuangan(false)}>
        <ModalHeader toggle={() => setShowKeuangan(false)}>
          Laporan Keuangan
        </ModalHeader>
        <ModalBody>
            <div className="row">
                <div className="col-12">
                  <iframe width="100%" height="500px" src="https://nos.jkt-1.neo.id/webdanain/tentangkami/LK_Audited_2020-Mulia_Inovasi_Digital.pdf#toolbar=0" />
                </div>
            </div>
        </ModalBody>
        </Modal>

        <Modal contentClassName="custom-modal-style" scrollable centered isOpen={show} toggle={() => setShow(false)}>
        <ModalHeader toggle={() => setShow(false)}>
          Keterbukaan Informasi
        </ModalHeader>
        <ModalBody>
            <div className="row">
                <div className="col-12">
                  <iframe width="100%" height="500px" src="https://nos.jkt-1.neo.id/webdanain/tentangkami/Keterbukaan_Informasi_Ver1.pdf#toolbar=0" />
                </div>
            </div>
        </ModalBody>
        </Modal>
      <div className="row footer-background pd-80-footer">
        <div className="row color-white">
          <div className="col-3">
            <img src={logodanain} />
            <div className="row pt-3">
              <div className="col-12 ">
                <span>Rukan Kirana Boutique Office Blok D2 No 7-8, Jln. Kirana Avenue II RT/RW. 000/000, Kel. Kelapa Gading Timur, Kec. Kelapa Gading, Kota Administrasi Jakarta Utara</span>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-12">
                <span className="bolder">Customer Service</span>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-2">
                <img src={logotelp} />
              </div>
              <div className="col-10">
                <span>021 28565355</span>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-2">
                <img src={logoemail} />
              </div>
              <div className="col-10">
                <span className="email">customer-care@danain.co.id</span>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              <div className="col-12 ">
                <span className="linking pointer" onClick={Beranda}>Danain</span>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-12">
                <span className="linking pointer" onClick={Tentangkami}>Tentang Kami</span>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-12">
                <span className="linking pointer" onClick={Hubkami}>Informasi Kontak</span>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-12">
                <span className="linking pointer" onClick={() => setShowKeuangan(true)}>Laporan Keuangan</span>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-12">
                <span className="linking pointer" onClick={Career}>Karier</span>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-12" onClick={()=> window.open("https://blog.danain.co.id/", "_blank")}>
                <span className="linking pointer">Blog</span>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-12">
                <span className="linking pointer" onClick={() => setShow(true)}>Keterbukaan Informasi</span>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              <div className="col-12 ">
                <span className="bolder">Dukungan</span>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-12">
                <span className="linking pointer" onClick={Faq}>FAQ</span>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-12">
                <span className="linking pointer" onClick={Syaratket}>Syarat dan Ketentuan</span>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-12">
                <span className="linking pointer" onClick={Kebijakanpriv}>Kebijakan Privasi</span>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-12">
                <span className="linking pointer" onClick={Pengaduanpengguna}>Pengaduan Pengguna</span>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-12">
                <span className="linking pointer" onClick={Resikopeminjam}>Risiko Penerima dan Pemberi Pinjaman</span>
              </div>
            </div>
            <div className="row pt-1">
              <div className="col-12">
                {/* <span className="linking pointer" onClick={Resikopendanaan}>Risiko Pemberi Pinjaman</span> */}
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row">
              <div className="col-12 ">
                <span className="bolder">Social Media</span>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-2 linking pointer" onClick={() => window.open("https://api.whatsapp.com/send?phone=+62811188291&text=", "_blank")}>
                <img src={logowa} />
              </div>
              <div className="col-2 linking pointer" onClick={() => window.open("https://www.instagram.com/danain.co.id/", "_blank")}>
                <img src={logoig} />
              </div>
              <div className="col-2 linking pointer" onClick={() => window.open("https://www.facebook.com/danain.co.id/", "_blank")}>
                <img src={logofb} />
              </div>
              <div className="col-2 linking pointer" onClick={() => window.open("https://twitter.com/danainid/", "_blank")}>
                <img src={logotwitter} />
              </div>
              {/* <div className="col-2 linking pointer" onClick={() => window.open("https://vt.tiktok.com/ZSekHaL5w/", "_blank")}>
                <img src={logotiktok} />
              </div> */}
            </div>
            <div className="row pt-4">
              <div className="col-12">
                <span className="bolder">Download</span>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-6 linking pointer" onClick={()=> window.open("https://play.google.com/store/apps/details?id=com.danainwbv.lender", "_blank")}>
                <img src={logogoogle} />
              </div>
              {/* <div className="col-6 linking pointer">
                <img src={logoappstore} />
              </div> */}
            </div>
          </div>    
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="border-hr"/>
          </div>
        </div>
        <div className="row">
          <div className="col-12 ">
            <span className="color-white bolder">Disclaimer Risiko</span>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <ol className="color-white2 ol-padding">
              <li className="li-padding pt-2">
                <span>Layanan Pinjam Meminjam Berbasis Teknologi Informasi merupakan kesepakatan perdata antara Pemberi Pinjaman dengan Penerima Pinjaman, sehingga segala risiko yang timbul dari kesepakatan tersebut ditanggung sepenuhnya oleh masing-masing pihak</span>
              </li>
              <li className="li-padding pt-2">
                <span>Risiko Kredit atau Gagal Bayar dan seluruh kerugian dari atau terkait dengan kesepakatan pinjam meminjam ditanggung sepenuhnya oleh Pendana. Tidak ada lembaga atau otoritas negara yang bertanggung jawab atas risiko gagal bayar dan kerugian tersebut.</span>
              </li>
              <li className="li-padding pt-2">
                <span>Penyelenggara dengan persetujuan dari masing-masing Pengguna (Pendana dan/atau Peminjam) mengakses, memperoleh, menyimpan, mengelola dan/atau menggunakan data pribadi Pengguna ('Pemanfaatan Data') pada atau di dalam benda, perangkat elektronik (termasuk smartphone atau telepon seluler), perangkat keras (hardware) maupun lunak (software), dokumen elektronik, aplikasi atau sistem elektronik milik Pengguna atau yang dikuasai Pengguna, dengan memberitahukan tujuan, batasan dan mekanisme Pemanfaatan Data tersebut kepada Pengguna yang bersangkutan sebelum memperoleh persetujuan yang dimaksud.</span>
              </li>
              <li className="li-padding pt-2">
                <span>Pendana yang belum memiliki pengetahuan dan pengalaman pinjam meminjam, disarankan untuk tidak menggunakan layanan ini.</span>
              </li>
              <li className="li-padding pt-2">
                <span>Peminjam harus mempertimbangkan tingkat bunga pinjaman dan biaya lainnya sesuai dengan kemampuan dalam melunasi pinjaman.</span>
              </li>
            </ol>
          </div>
          <div className="col-6">
            <ol start="6" className="color-white2 ol-padding">
              <li className="li-padding pt-2">
                <span>Setiap kecurangan tercatat secara digital di dunia maya dan dapat diketahui masyarakat luas di media sosial.</span>
              </li>
              <li className="li-padding pt-2">
                <span>Pengguna harus membaca dan memahami informasi ini sebelum membuat keputusan menjadi Pendana atau Peminjam.</span>
              </li>
              <li className="li-padding pt-2">
                <span>Pemerintah yaitu dalam hal ini Otoritas Jasa Keuangan,tidak bertanggung jawab atas setiap pelanggaran atau ketidakpatuhan oleh Pengguna,baik Pendana maupun Peminjam(baik karena kesengajaan atau kelalaian Pengguna)terhadap ketentuan peraturan perundang-undangan maupun kesepakatan atau perikatan antara Penyelenggara dengan Pendana dan/atau Peminjam.</span>
              </li>
              <li className="li-padding pt-2">
                <span>Setiap transaksi dan kegiatan pinjam meminjam atau pelaksanaan kesepakatan mengenai pinjam meminjam antara atau yang melibatkan Penyelenggara,Pendana dan/atau Peminjam wajib dilakukan melalui escrow account dan virtual account sebagai mana yang diwajibkan berdasarkan Peraturan Otoritas Jasa Keuangan Nomor 10/POJK.05/2022 tentang Layanan Pendanaan Bersama Berbasis Teknologi Informasi dan pelanggaran atau ketidakpatuhan terhadap ketentuan tersebut merupakan bukti telah terjadinya pelanggaran hukum oleh Penyelenggara sehingga Penyelenggara wajib menanggung ganti rugi yang diderita oleh masing-masing Pengguna sebagai akibat langsung dari pelanggaran hukum tersebut diatas tanpa mengurangi hak Pengguna yang menderita kerugian menurut Kitab Undang-Undang Hukum Perdata.</span>
              </li>
            </ol>
          </div>

        </div>
        <div className="row color-white2">
          <div className="col-12">
            <span>PT Mulia Inovasi Digital dengan platform danaIN telah resmi berizin di Otoritas Jasa Keuangan ("OJK") sebagai Penyelenggara Layanan Pinjam Meminjam Uang Berbasis Teknologi Informasi (peer to peer lending) dengan Surat Tanda Bukti Berizin dari OJK Nomor KEP-84DD.05/2021 tanggal 24 Agustus 2021 sehingga semua pelaksanaan kegiatan usahanya diawasi secara ketat oleh OJK berdasarkan Peraturan Otoritas Jasa Keuangan Nomor 10/POJK.05/2022 tentang Layanan Pendanaan Bersama Berbasis Teknologi Informasi</span>
          </div>
        </div>

        <div className="col-12 footer-warning">
                <p>
                “HATI - HATI, TRANSAKSI INI BERISIKO TINGGI. ANDA DAPAT SAJA MENGALAMI KERUGIAN ATAU KEHILANGAN UANG. JANGAN BERHUTANG JIKA TIDAK MEMILIKI KEMAMPUAN MEMBAYAR. PERTIMBANGKAN SECARA BIJAK SEBELUM BERTRANSAKSI”
                </p>
          </div>
          
      </div>
      <div className="row footer-footer-background ">
        <div className="col-12 color-white text-center p-3">
          <img src={copyright} />
        
          <span>   {dayjs().format("YYYY")} PT Mulia Inovasi Digital. All rights reserved.</span>
        </div>
      </div>
      
    </>
  );
};

export default FooterPages;