import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import HeaderPages from "./components/HeaderPages/HeaderPages";
import Loading from "./components/Loading/Loading";

const NotFound = lazy(() => import("./pages/Notfound/Notfound"));
const Peminjam = lazy(() => import("./pages/Peminjam/Peminjam"));
const Beranda = lazy(() => import("./pages/Beranda/Beranda"));
const Pendana = lazy(() => import("./pages/Pendana/Pendana"));
const TentangKami = lazy(() => import("./pages/TentangKami/TentangKami"));
const Hubkami = lazy(() => import("./pages/Hubungikami/Hubungikami"));
const Syaratket = lazy(() => import("./pages/Syaratketentuan/Syaratket"));
const Kebijakanpriv = lazy(() => import("./pages/Kebijakanprivasi/Kebijakanpriv"));
const Resikopeminjam = lazy(() => import("./pages/Resikopeminjam/Resikopeminjam"));
const Resikopendanaan = lazy(() => import("./pages/Resikopendanaan/Resikopendanaan"));
const Bantuan = lazy(() => import("./pages/Bantuan/Bantuan"));
const BantuanDetail = lazy(() => import("./pages/Bantuan/BantuanDetail"));
const PengaduanPengguna = lazy(() => import("./pages/PengaduanPengguna/PengaduanPengguna"));
const Career = lazy(() => import("./pages/Career/Career"));
const Detailcareer = lazy(() => import("./pages/Detailcareer/Detailcareer"));
const Applycareer = lazy(() => import("./pages/Applycareer/Applycareer"));

export const App = (props) => {
  if (window.innerWidth < 1024) {
    // window.location.href = "https://play.google.com/store/apps/details?id=com.danainwbv.lender";
    window.location.href = "https://app.danain.co.id";
    return <div></div>;
  }
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/"><Redirect to="/beranda" /></Route>
          <Route path="/peminjam">
            <HeaderPages>
              <Peminjam />
            </HeaderPages>
          </Route>
          <Route path="/beranda">
            <HeaderPages>
              <Beranda />
            </HeaderPages>
          </Route>
          <Route path="/pendana">
            <HeaderPages>
              <Pendana />
            </HeaderPages>
          </Route>
          <Route path="/tentang-kami">
            <HeaderPages>
              <TentangKami />
            </HeaderPages>
          </Route>
          <Route path="/hubungikami">
            <HeaderPages>
              <Hubkami />
            </HeaderPages>
          </Route>
          <Route path="/syaratketentuan">
            <HeaderPages>
              <Syaratket />
            </HeaderPages>
          </Route>
          <Route path="/kebijakanprivasi">
            <HeaderPages>
              <Kebijakanpriv />
            </HeaderPages>
          </Route>
          <Route path="/resikopeminjam">
            <HeaderPages>
              <Resikopeminjam />
            </HeaderPages>
          </Route>
          <Route path="/resikopendanaan">
            <HeaderPages>
              <Resikopendanaan />
            </HeaderPages>
          </Route>
          <Route path="/bantuan">
            <HeaderPages>
              <Bantuan />
            </HeaderPages>
          </Route>
          <Route path="/bantuan-detail">
            <HeaderPages>
              <BantuanDetail />
            </HeaderPages>
          </Route>
          <Route path="/pengaduan-pengguna">
            <HeaderPages>
              <PengaduanPengguna />
            </HeaderPages>
          </Route>
          <Route path="/career">
            <HeaderPages>
              <Career />
            </HeaderPages>
          </Route>
          <Route path="/detail-career">
            <HeaderPages>
              <Detailcareer />
            </HeaderPages>
          </Route>
          <Route path="/apply-career">
            <HeaderPages>
              <Applycareer />
            </HeaderPages>
          </Route>
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};
