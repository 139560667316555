import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import "./HeaderPages.css";
import "../FooterPages/FooterPages";
import FooterPages from "../FooterPages/FooterPages";
import logoDanain from "../../styles/images/header/logoDANAIN1.png";
import line from "../../styles/images/header/Rectangle487.png";
import infotkb from "../../styles/images/header/infotkb.png";
import tkbhitung from "../../styles/images/tkb-hitung.png";
import segitiga from "../../styles/images/segitiga.png";
import AuthApi from "../../Services/auth/auth.api.js";
import UsersApi from "../../Services/users/users.api.js";
import Drift from "react-driftjs";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Modal } from "reactstrap";
import CloseButton from "../../styles/images/Close.svg";
import WaButton from "../../styles/images/wa-white.svg";

const HeaderPages = ({ children }) => {
  const selectRef = useRef();
  const [hoverPeminjam, setHoverPeminjam] = useState(false);
  let history = useHistory();
  const [modalTKB, setModalTKB] = useState(false);
  const [TKB, setTKB] = useState({
    tkb: 0,
  });
  const [selectedTKB, setSelectedTKB] = useState();

  const optionTKB = [
    {
      jenisTKB: "90",
      nominal: "90 (sembilan puluh)",
      value: TKB?.result?.tkb90,
    },
    {
      jenisTKB: "60",
      nominal: "60 (enam puluh)",
      value: TKB?.result?.tkb60,
    },
    {
      jenisTKB: "30",
      nominal: "30 (tiga puluh)",
      value: TKB?.result?.tkb30,
    },
    {
      jenisTKB: "0",
      nominal: "0 (nol)",
      value: TKB?.result?.tkb0,
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
    },
  };

  const peminjam = history.location.pathname === "/peminjam";
  const pendana = history.location.pathname === "/pendana";
  const tentangkami = history.location.pathname === "/tentang-kami";

  useEffect(() => {
    AuthApi.CounterHit().then((data) => {
      console.log("counter");
    });
    UsersApi.getTKB().then((data) => {
      console.log("TKB", data);
      setTKB(data);
    });
  }, []);

  function ParseFloat(str, val) {
    str = str.toString();
    str = str.slice(0, str.indexOf(".") + val + 1);
    return Number(str);
  }

  // if (document.getElementById("drift-frame-controller") !== null) {
  //   document.getElementById("drift-frame-controller").style.display = "none";
  //   document.getElementById("drift-frame-chat").style.opacity = 0;
  // }

  const CustomDot = ({ ...rest }) => {
    const { active, onClick } = rest;
    return <button className={active ? "dot-header dot-header-active" : "dot-header dot-header-nonactive"} onClick={() => onClick()} />;
  };

  return (
    <>
      <div className="wa-button" onClick={() => window.open("https://api.whatsapp.com/send?phone=62811188291&text=Halo%2C%20saya%20ingin%20bertanya%20tentang%C2%A0Danain", "_blank")}>
        <img src={WaButton} width={"27px"} height={"27px"} />
      </div>

      <div className="header-container fixed-row">
        <div className="row align-center-item">
          <div className="col-6">
            <div className="logo">
              <img src={logoDanain} className="pointer" onClick={() => history.push("/beranda")} />
            </div>
          </div>
          <div className="col-6 text-right">
            <div className="row padding-right-80">
              <div className="col-3 pointer" onClick={() => history.push("/peminjam")}>
                <div className="tittle-pinjaman">
                  <span className={peminjam ? "page-active" : ""}>Pinjaman</span>
                  {/* <div className="line">
                    <img src={line} />
                  </div> */}
                </div>
              </div>
              <div className="col-3 pointer" onClick={() => history.push("/pendana")}>
                <div className="tittle-pendanaan">
                  <span className={pendana ? "page-active" : ""}>Pendanaan</span>
                </div>
              </div>
              <div className="col-3 pointer" onClick={() => history.push("/tentang-kami")}>
                <div className="tittle-tetkami">
                  <span className={tentangkami ? "page-active" : ""}>Tentang Kami</span>
                </div>
              </div>
              <div className="col-3 pointer tittle-tkb">
                <Carousel
                  responsive={responsive}
                  ssr={false}
                  showDots={true}
                  arrows={false}
                  customDot={<CustomDot />}
                  transitionDuration={500}
                  infinite
                  autoPlay={!modalTKB}
                  autoPlaySpeed={5000}
                  pauseOnHover
                  swipeable={false}
                  draggable={false}
                  className="tkb-carousel"
                  dotListClass="dot-class-tkb"
                >
                  {optionTKB?.map((item) => (
                    <div
                      onClick={() => {
                        setModalTKB(true);
                        setSelectedTKB(item);
                      }}
                    >
                      <div style={{ textAlign: "left" }}>
                        TKB {item?.jenisTKB} <img src={infotkb} className="ms-1" />
                      </div>
                      <div className="persentase">
                        <span>{item?.value?.toString().includes(".") ? item?.value?.toString().slice(0, item?.value?.toString().indexOf(".") + 2 + 1) : item?.value}%</span>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
              {/* <div className="col-3 pointer" onMouseEnter={() => setModalTKB("block")} onMouseLeave={() => setModalTKB("none")}>
                <div className="tittle-tkb" onMouseEnter={() => setModalTKB("block")} onMouseLeave={() => setModalTKB("none")}>
                  <span>TKB 90</span>
                </div>
                <div className="info-tkb" onMouseEnter={() => setModalTKB("block")} onMouseLeave={() => setModalTKB("none")}>
                  <span><img src={infotkb} /></span>
                </div>
                <div className="persentase" onMouseEnter={() => setModalTKB("block")} onMouseLeave={() => setModalTKB("none")}>
                <span>{TKB?.tkb?.toString().includes(".") ? TKB?.tkb?.toString().slice(0, (TKB?.tkb?.toString().indexOf(".")) + 2 + 1) : TKB?.tkb}%</span> 
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <Modal centered isOpen={modalTKB} toggle={() => setModalTKB(false)} className="modal-width">
          <img src={CloseButton} className="close-button-tentang-kami" onClick={() => setModalTKB(false)} />
          <div className="blog-card box-item p-32px">
            <div className="row">
              <div className="col-12">
                <span className="text-tkb-90">TKB {selectedTKB?.jenisTKB}</span>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-12">
                <span className="text-bawah-tkb">
                  TKB {selectedTKB?.jenisTKB} adalah ukuran tingkat keberhasilan Penyelenggara dalam memfasilitasi penyelesaian kewajiban Pendanaan dalam jangka waktu sampai dengan {selectedTKB?.nominal} hari kalender terhitung sejak jatuh
                  tempo.
                </span>
              </div>
            </div>
            <div className="custom-row pt-4">
              <div className="twp">
                <span className="header-twp">
                  TKB {selectedTKB?.jenisTKB} = 100% − TWP {selectedTKB?.jenisTKB}
                </span>
                <div className="hitungan-twp">
                  <span className="fs-18 fw-500">TWP {selectedTKB?.jenisTKB} = </span>
                  <div className="col-custom text-center">
                    <span>Posisi akhir wanprestasi di atas {selectedTKB?.jenisTKB} hari kalender</span>
                    <hr style={{ border: "1px #777777 solid", margin: 0, opacity: 1 }} />
                    <span>Total posisi akhir</span>
                  </div>
                  <span style={{ color: "black" }}>X 100%</span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      {children}
      <FooterPages />
    </>
  );
};

export default HeaderPages;
