import * as axios from "axios";
import qs from "querystring";
import { toast } from "react-toastify";
import { doEraseAllReload, getToken } from "./cookie";
import Formatting from "./formattingJson";
import Messages from "./message";
import dayjs from "dayjs";

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Formatting.consoleWrite({
    //   value: error.response.data,
    //   name: "interceptors.error",
    // });

    if (error.response === undefined) {
      toast.success("Ups, terjadi kesalahan!", {
        bodyClassName: "capitalize",
      });
    }

    if (error?.response?.data?.status === 401) {
      toast(Messages.unauthorized);
      setTimeout(() => {
        doEraseAllReload();
      }, 3000);
    }

    if (error?.response?.data?.status === 404) {
      Formatting.consoleWrite({
        value: error.response.data.message,
        name: "interceptors.response0001",
      });

      toast.success(error?.response?.data?.message, {
        bodyClassName: "capitalize",
      });
      // setTimeout(() => {
      //   doEraseAllReload();
      // }, 3000);
    }

    if (error?.response?.data?.status === 400) {
      Formatting.consoleWrite({
        value: error.response.data.message,
        name: "interceptors.response0001",
      });

      toast.success(error?.response?.data?.message, {
        bodyClassName: "capitalize",
      });
      // setTimeout(() => {
      //   doEraseAllReload();
      // }, 3000);
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

const Api = () => ({
  Config: ({ withoutToken, multipartForm, body, typeService, url, appCheckVersion, rawJSON }) => {
    let Api_token = getToken("SID");
    const basicAuth = Buffer.from(`${process.env.REACT_APP_UNAME_AUTH}:${process.env.REACT_APP_PASSWD_AUTH}`, 'utf8').toString('base64')
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      // "golang-auth": "golang",
      "Authorization": `Basic ${basicAuth}`,
    };

    if (Api_token) {
      headers.token = `${Api_token}`;
    }

    if (withoutToken) {
      delete headers.token;
    }

    let timestamp = dayjs().tz("Asia/Jakarta").format("YYYY-MM-DDTHH:mm+[07:00]")
    let jwt = multipartForm ? Formatting.GenerateJWTFormData(body, timestamp, process.env.REACT_APP_API_ENDPOINT+url?.split("?")[0]) : Formatting.GenerateJWT(body, timestamp, process.env.REACT_APP_API_ENDPOINT+url?.split("?")[0])
    headers["api-key"] = process.env.REACT_APP_API_KEY;
    headers["x-SIGNATURE-key"] = jwt?.split('.')[2];
    headers["timestamp"] = timestamp;


    if (multipartForm) {
      headers["Content-Type"] = "multipart/form-data";
    }

    if (rawJSON) {
      headers["Content-Type"] = "application/json";
    }


    const client = {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      headers: headers,
    };
    return client;
  },
  Get: (url = "", config) => {
    return axios
      .get(
        url,
        Api().Config({
          url: url,
          body: {},
          withoutToken: config?.withoutToken,
          appCheckVersion: config?.appCheckVersion,
        })
      )
      .then((res) => res?.data || {});
  },
  Post: (url, body, config) => {
    return axios
      .post(
        url,
        qs.stringify(body),
        Api().Config({body,  withoutToken: config?.withoutToken })
      )
      .then((res) => res?.data || {});
  },
  PostFile: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ body, multipartForm: config?.multipartForm }))
      .then((res) => res?.data || {});
  },
  postRaw: (url, body, config) => {

    return axios
      .post(url, body, Api().Config({ body, rawJSON: config?.rawJSON }))
      .then((res) => res?.data || {});
  },
});

export default Api;
