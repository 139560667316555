import payung from "../../styles/images/payung.svg";
const Loading = () => {
  return (
    <div className="outer-container">
      <div className="inner-container">
        <div className="centered-content">
          <div className="inner-loading">
            <img src={payung} />
          </div>
          <div>
            <span className="text-loading color-green">Mohon Menunggu<span className="menunggu">...</span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;